export default (axios) => ({
  // CRUD functions
  list(payload) {
    return axios.get('v1/clients', { params: payload })
  },
  create(data) {
    return axios.post('v1/clients', data)
  },
  stateAccount(client = false, payload) {
    if (client) {
      return axios.get(`v1/client_account_status/${client}`, { params: payload })
    }
    return axios.get('v1/client_account_status', { params: payload })
  },
  creditNotes(client = false, payload) {
    if (client) {
      return axios.get(`v1/client_credit_notes/${client}`)
    }
    return axios.get('v1/client_credit_notes', { params: payload })
  },
  info(client = false) {
    if (client) {
      return axios.get(`v1/client_info/${client}`)
    }
    return axios.get('v1/client_info')
  },
  orders(client = false, payload, endpoint = 'orders') {
    if (client) {
      return axios.get(`v1/${endpoint}/current_user_orders/${client}`, { params: payload })
    }
    return axios.get(`v1/${endpoint}/current_user_orders`, { params: payload })
  },
  update(id, data) {
    return axios.put(`v1/clients/${id}`, data)
  },
  destroy(id) {
    return axios.delete(`v1/clients/${id}`)
  },

  // NO-CRUD functions
  changePassword(id, payload) {
    return axios.put(`v1/clients/${id}/change_password`, payload)
  },

  changeStatusClient(id, payload) {
    return axios.put(`v1/clients/${id}/toggle_client`, payload)
  },

  // Clients prospects
  listProspects(payload) {
    return axios.get('v1/clients_prospect', { params: payload })
  },
  createProspectPublic(payload) {
    return axios.post('v1/client_prospect', payload)
  },
  createProspectsAdmin(payload) {
    return axios.post('v1/clients_prospect', payload)
  },
  updateProspectsAdmin(id, payload) {
    return axios.put(`v1/clients_prospect/${id}`, payload)
  },
  destroyProspectsAdmin(id) {
    return axios.delete(`v1/clients_prospect/${id}`)
  },
  changePasswordProspects(id, payload) {
    return axios.put(`v1/clients_prospect/${id}/change_password`, payload)
  },
  changeStatusClientProspects(id, payload) {
    return axios.put(`v1/clients_prospect/${id}/toggle`, payload)
  },
  processOrders() {
    return axios.post('v1/clients/process_all')
  },
  activeClients(location) {
    return axios.get(`v1/clients/active_clients`, { params: location })
  },
  activeClientsByZone(zone) {
    return axios.get(`v1/clients/${zone}/active_clients_zone`)
  },
  activeClientsBySeller() {
    return axios.get(`v1/clients/0/active_clients_zone`)
  },
  rejectClient(id, payload) {
    return axios.post(`v1/clients/${id}/reject`, payload)
  },
  totalsStatus(payload) {
    return axios.get('v1/clients/statuses', { params: payload })
  },
  export(payload) {
    return axios.get('v1/clients/export', { params: payload })
  },
  exportAverages() {
    return axios.get('v1/clients/export_average')
  },
  updateCressClient(id) {
    return axios.post(`v1/clients/${id}/update_info`)
  },
  listBuenFin(payload) {
    return axios.get('v1/clients/buen_fin', { params: payload })
  }
})
