<script setup>
import WhatsAppButton from '@/components/layout/social/WhatsAppButton.vue'
import LgViewFooter from '@/components/layout/navigation/footer/LgViewFooter.vue'
import MdViewFooter from '@/components/layout/navigation/footer/MdViewFooter.vue'
import InfoVideo from '@/components/shared/InfoVideo.vue'
import configuration from '@/constants/navigation'
import { useRoute } from 'vue-router'
import { computed, ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'

const brandName = import.meta.env.VITE_BRAND_NAME
const configsData = configuration[brandName] || configuration.default
const { footerSections, servicesOptionsByUserType } = configsData

const { baseApp, services, customerSupport, policy, socialNetworks } = footerSections

const defaultSectionOrder = ['baseApp', 'services', 'customerSupport', 'policy']
const userStore = useUserStore()
const authStore = useAuthStore()
const infoVideoModal = ref(null)
const isLoggedIn = computed(() => authStore.isLoggedIn)
services.options[0].routeName = userStore.routeToDasboardByUserType
const route = useRoute()

const userType = computed(() => userStore.userType)

const servicesByLoggedUserType = computed(
  () => servicesOptionsByUserType[userType.value] || servicesOptionsByUserType['noUser']
)

const dynamicFooterSections = computed(() => {
  const withoutUser = { baseApp, customerSupport, policy }
  if (!isLoggedIn.value) {
    return withoutUser
  }
  let dynamicServicesOptions = JSON.parse(JSON.stringify(services))
  if (servicesByLoggedUserType.value.includes('allOptions')) {
    dynamicServicesOptions.options = dynamicServicesOptions.options.filter(
      (service) => service.key !== 'signUpProspect' && service.key !== 'login'
    )
    return { baseApp, services: dynamicServicesOptions, customerSupport, policy }
  }
  dynamicServicesOptions.options = dynamicServicesOptions.options.filter((service) =>
    servicesByLoggedUserType.value.includes(service.key)
  )
  return { baseApp, services: dynamicServicesOptions, customerSupport, policy }
})

const sectionOrder = computed(() => {
  const arraySectionsKeys = Object.keys(dynamicFooterSections.value)
  const order = defaultSectionOrder.filter((section) => arraySectionsKeys.includes(section))
  return order
})

const openTutorialModal = () => {
  infoVideoModal.value.showModal()
}
</script>
<template>
  <div class="mt-auto">
    <InfoVideo ref="infoVideoModal" allVideos />
    <WhatsAppButton v-if="!route.path.includes('admin')" />
    <div class="footerComponent">
      <div
        class="footerComponent__main row justify-content-lg-center align-items-start justify-content-start col-12"
      >
        <!-- LgViewFooter -->
        <LgViewFooter
          :sections="dynamicFooterSections"
          :socialNetworks="socialNetworks"
          :sectionOrder="sectionOrder"
          @infoVideo="openTutorialModal"
        />
        <!-- LgViewFooter -->
        <!-- MdViewFooter -->
        <MdViewFooter
          :sections="dynamicFooterSections"
          :socialNetworks="socialNetworks"
          :sectionOrder="sectionOrder"
          @infoVideo="openTutorialModal"
        />
        <!-- MdViewFooter -->
      </div>

      <div class="footerComponent__secondary col-12 p-3">
        <div class="col">
          <h5 class="text-lg-center text-center mb-0">
            ® 2024 Dinámica Ferretera S.A. de C.V. Todo los derechos reservados
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>
