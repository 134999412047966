import configuration from '@/constants/navigation'

const brandName = import.meta.env.VITE_BRAND_NAME
const configsData = configuration[brandName] || configuration.default
const { footerSections, servicesOptionsByUserType } = configsData
const { services } = footerSections

const navbarConfigDinamica = { services, servicesOptionsByUserType }

export default navbarConfigDinamica
